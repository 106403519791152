//lib

import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import SymbolLine from "../../../components/common/symbol.line";

//assets

//reducers

//components

export default function Announcement({ announcement, loading }) {
    const dropdownRef = useRef(null);
    const [dropAnnouncement, setDropAnnouncement] = useState(false);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropAnnouncement(false);
      }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropAnnouncement]);

    return (
      <div>
        <div className="store-announcement mt-4" ref={dropdownRef}>
          <div 
            className="flex justify-between" 
            onClick={() => setDropAnnouncement(!dropAnnouncement)}
          >
            <div>
              <Icon
                icon="emojione-v1:bull-horn-with-sound-waves" 
                // color={"#fdfdff"} 
                width={24} 
                height={24} 
              />
            </div>
            <div className="flex justify-center items-center w-100">
              <div className={`_label --announcement text-start ${!dropAnnouncement ? 'one-line' : ''}`}>
                  {/* <div >
                  We embark on a collaborative journey to understand your goals, target audience, and unique brand identity.
                  </div>
                  <div className="line" />
                  <div>
                  Empowered by a versatile toolbox and honed skills, I utilize cutting-edge technologies to bring digital visions to life. Each tool is carefully selected to elevate projects
                  </div>
                  <div className="line" />
                  <div>
                  ensuring they stand out in the ever-evolving landscape of technology and design.
                  </div>
                  <div className="line" />
                  <div>
                  We embark on a collaborative journey to understand your goals, target audience, and unique brand identity.
                  </div> */}
                {!loading && announcement.length > 0 && announcement.map((announce, announceIndex) => (
                  <>
                    <div>
                      {announce.content}
                    </div>
                    {dropAnnouncement && <div className="line" />}
                  </>
                ))}
              </div>
            </div>
            <div>
              <Icon
                icon={dropAnnouncement ? "octicon:chevron-up-16" : "octicon:chevron-down-16" }
                color={"#006294"} 
                width={20} 
                height={20} 
              />
            </div>
          </div>
        </div>
      </div>
    )
}