import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Map, Store } from "../../assets/images";
import { openSaveAddressModal } from "./modalReducer";
import apiServices from "../../services/api.services";

export const getOutletList = createAsyncThunk(
  "getOutletList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getOutletList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getGuestToken = createAsyncThunk(
  "getGuestToken",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getGuestToken(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAddressList = createAsyncThunk(
  "getAddressList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getAddressList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAddressInfo = createAsyncThunk(
  "getAddressInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getAddressInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "deleteAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.deleteAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setAddress = createAsyncThunk(
  "setAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.createAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "updateAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.updateAddress(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  outletList: [
    // {
    //     name: 'Jaya One',
    //     located_at: 'The School',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
    // {
    //     name: 'One Utame',
    //     located_at: 'Old Wing',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
    // {
    //     name: 'Jaya One',
    //     located_at: 'The School',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
    // {
    //     name: 'One Utame',
    //     located_at: 'Old Wing',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
    // {
    //     name: 'Jaya One',
    //     located_at: 'The School',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
    // {
    //     name: 'One Utame',
    //     located_at: 'Old Wing',
    //     address: 'Block J, The School, Jaya One, No. 72A, Jalan Universiti 46200 Petaling Jaya Selangor',
    //     image: Store,
    //     map: Map
    // },
  ],
  // addressList: JSON.parse(localStorage.getItem("savedAddresses")) || [],
  addressList: [],
  addressListLoading: false,

  addressId: "",
  addressInfo: {},
  outletId: localStorage.getItem("outletId") || "",
  addressMenu: [
    {
      label: "Edit",
      icon: "iconamoon:edit-light",
      action: "edit",
    },
    {
      label: "Delete",
      icon: "ep:delete",
      action: "delete",
    },
  ],
  typingAddress: "",
  findAddress: {},
  noAddressFound: false,
  loading: false,
  shippingType: "pickup",
  outletLoading: false,
};

const dataSlice = createSlice({
  name: "store",
  initialState: initialData,
  reducers: {
    setAddressList: (state, action) => {
      state.addressList = action.payload;
    },
    setTypingAddress: (state, action) => {
      state.typingAddress = action.payload;
    },
    setAddressId: (state, action) => {
      state.addressId = action.payload;
    },
    // setOutletId: (state, action) => {
    //   state.outletId = localStorage.getItem('outletId');
    // },
    setNoAddressFound: (state, action) => {
      state.noAddressFound = action.payload;
    },
    setFindAddress: (state, action) => {
      state.findAddress = action.payload;
    },
    setOutletLoading: (state, action) => {
      state.outletLoading = action.payload;
    },
    clearAddressInfo: (state, action) => {
      state.addressInfo = {};
    },
  },
  extraReducers: {
    [getOutletList.pending]: (state, action) => {
      state.outletLoading = true;
    },
    [getOutletList.fulfilled]: (state, action) => {
      state.outletLoading = false;
      state.outletList = action.payload.data;
    },
    [getOutletList.rejected]: (state, action) => {
      state.outletLoading = false;
    },

    [getGuestToken.pending]: (state, action) => {
      state.loading = true;
    },
    [getGuestToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.guestToken = localStorage.setItem(
        "token",
        action.payload.data.token
      );
    },
    [getGuestToken.rejected]: (state, action) => {
      state.loading = false;
    },

    [getAddressList.pending]: (state, action) => {
      state.addressListLoading = true;
    },
    [getAddressList.fulfilled]: (state, action) => {
      state.addressListLoading = false;
      state.addressList = action.payload.data;
    },
    [getAddressList.rejected]: (state, action) => {
      state.addressListLoading = true;
    },

    [getAddressInfo.pending]: (state, action) => {
      state.loading = true;
    },
    [getAddressInfo.fulfilled]: (state, action) => {
      state.loading = false;
      state.addressInfo = action.payload.data;
    },
    [getAddressInfo.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteAddress.rejected]: (state, action) => {
      state.loading = false;
    },
    // create address
    [setAddress.pending]: (state, action) => {
      // state.loading = true;
    },
    [setAddress.fulfilled]: (state, action) => {
      // state.loading = false;
    },
    [setAddress.rejected]: (state, action) => {
      // state.loading = false;
    },
    // update address
    [updateAddress.pending]: (state, action) => {
      // state.loading = true;
    },
    [updateAddress.fulfilled]: (state, action) => {
      // state.loading = false;
      state.addressInfo = action.payload;
    },
    [updateAddress.rejected]: (state, action) => {
      // state.loading = false;
    },
  },
});

export const {
  setToken,
  setAddressList,
  setTypingAddress,
  setAddressId,
  setOutletId,
  setNoAddressFound,
  setFindAddress,
  clearAddressInfo,
  setOutletLoading,
} = dataSlice.actions;

export default dataSlice.reducer;
