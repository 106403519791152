import { useEffect, useRef, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useSearchParams } from "react-router-dom";
import { MDBContainer, MDBModalBody } from "mdb-react-ui-kit";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//reducers
import { useDispatch, useSelector } from "react-redux";
import {
  clearAddressInfo,
  setFindAddress,
  setTypingAddress,
} from "../../../redux/reducer/storeReducer";
import {
  closeSetAddressModal,
  openSaveAddressModal,
  setAnimationModal,
} from "../../../redux/reducer/modalReducer";

//components
import AddressListing from "./components/address.listing";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import AddressInput from "../../../components/common/search.address.input";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

export default function SetAddress({ addresses }) {
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();

  const [top, setTop] = useState();
  const [search, setSearch] = useState("");

  const { isOpenSetAddressModal } = useSelector((state) => state.modal);
  const { typingAddress, noAddressFound, findAddress, addressList } =
    useSelector((state) => state.store);

  const backButton = () => {
    dispatch(closeSetAddressModal());
    setSearchParams('')
  };

  const handleClear = () => {
    dispatch(clearAddressInfo());
    dispatch(setFindAddress({}));
    dispatch(setTypingAddress(""));
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handlePlaceSelected = (place) => {
    dispatch(setFindAddress(place));
    dispatch(setTypingAddress(""));
    handleNextPage();
  };

  const handleNextPage = () => {
    dispatch(openSaveAddressModal());
    dispatch(setAnimationModal(false))
    setSearchParams('')
  };

  useEffect(() => {
    setTop(true);
    handleClear();
  }, [isOpenSetAddressModal]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenSetAddressModal}
      backButton={backButton}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName=""
      content={
        <>
          <ModalHeader
            title="Set Your Address"
            backButton={backButton}
            backButtonNoAnimation={backButton}
          />
          <MDBModalBody ref={modalRef}>
            <MDBContainer className="set-address-container">
              <div
                className="search-bar"
                style={{
                  borderBottomLeftRadius: 25,
                  borderBottomRightRadius: 25,
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                }}
              >
                <div>
                  <Icon
                    icon="eva:search-outline"
                    color="#878585"
                    width={20}
                    height={20}
                  />
                </div>
                <div className="ms-4 w-100">
                  <AddressInput
                    onPlaceSelected={handlePlaceSelected}
                    searchResult={typingAddress}
                    ref={inputRef}
                  />
                </div>
                <div onClick={() => handleClear()}>
                  <Icon
                    icon="basil:cross-solid"
                    color={typingAddress ? "#878585" : "transparent"}
                    width={30}
                    height={30}
                  />
                </div>
              </div>
              {noAddressFound && typingAddress.length > 4 && (
                <label className="error-msg mt-2">
                  Couldn't find your location.{" "}
                  <span onClick={() => handleNextPage()}>Click here</span>
                </label>
              )}
              <AddressListing addresses={addressList} />
            </MDBContainer>
          </MDBModalBody>
        </>
      }
    />
  );
}
