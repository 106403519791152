import React from 'react'
import { useRef } from 'react'

//redux
import { useSelector } from 'react-redux'

//lib
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'

//assets
import PolicyDropdown from './policy.dropdown'
import PolicyScroll from './policy.scroll'
import useWindowDimensions from '../../../components/hook/use.window.dimensions'

//component

export default function TermsConditions() {
  const { width } = useWindowDimensions()

  const { policy_item, tc_item, referral_item } = useSelector((state) => state.common)

  const section1 = useRef(null)
  const section2 = useRef(null)
  const section3 = useRef(null)
  const containerRef = useRef(null)
  
  return (
      <MDBContainer className="tnc-container">
      <MDBRow className="text-center">
        <label className="_label --main-title">
          Terms of Use
        </label>
      </MDBRow>
      <MDBRow className="text-center">
        <label className="_label --main-subtitle mt-3">
            We believe service the best, values the customer and honestly always the best policy at
            all time
        </label>
      </MDBRow>
      
      <PolicyDropdown section1={section1} section2={section2} section3={section3}/>
      
      <MDBRow className='justify-center mt-5'>
        {width > 991 && (
            <MDBCol className={`col-${width < 1200 ? 4 : 3} t_c-row text-center`}>
            <PolicyScroll 
                containerRef={containerRef} 
                section1={section1} 
                section2={section2} 
                section3={section3}
            />
            </MDBCol>
        )}
        <MDBCol className={width > 991 ? width > 1200 ? 'col-9' : 'col-8'  : 'col-12'}>
          <div ref={containerRef}>
            <div id="section1" ref={section1}>
              <MDBRow>
                <label className="_label --section-title mt-3 mb-3">
                    Terms and Conditions
                </label>
              </MDBRow>
              {tc_item.map((tcItem, tcIndex) => {
                  return (
                  <div key={tcIndex} className='mt-3'>
                    <MDBRow>
                      <label className="_label --tnc-title">{tcIndex + 1}. {tcItem.title}</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --tnc-content mt-2 mb-4">
                        {tcItem.content}
                      </label>
                    </MDBRow>
                  </div>
                )
            })}
            </div>
            <div id="section2" ref={section2}>
              <MDBRow>
                <label className="_label --section-title mt-5 mb-3">
                    Privacy Policy
                </label>
              </MDBRow>
              {policy_item.map((policyItem, policyIndex) => {
                  return (
                      <div key={policyIndex} className="mt-3">
                    <MDBRow>
                      <label className="_label --tnc-title">{policyIndex + 1}. {policyItem.title}</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --tnc-content mt-2 mb-4">
                        {policyItem.content}
                      </label>
                    </MDBRow>
                  </div>
                )
            })}
            </div>
            <div id="section3" ref={section3}>
              <MDBRow>
                <label className="_label --section-title mt-5 mb-3">
                    Referral Program Terms and Conditions
                </label>
              </MDBRow>
              {referral_item.map((referralItem, referralIndex) => {
                  return (
                      <div key={referralIndex} className="mt-3">
                    <MDBRow>
                      <label className="_label --tnc-title">{referralIndex + 1}. {referralItem.title}</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --tnc-content mt-2 mb-4">
                        {referralItem.content}
                      </label>
                    </MDBRow>
                  </div>
                )
              })}
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
