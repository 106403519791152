//lib
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearCart } from '../redux/reducer/checkoutReducer';
import apiServices from './api.services';

function handleErrorResponse(error) {
  const authError = error.response && error.response.status === 401;
  const badReqError = error.response && error.response.status === 400;
  const manyAttemptsError = error.response && error.response.status === 429;
  const unexpectedError = error.response && error.response.status === 500;
  
  const cartId = localStorage.getItem('cartId');
  const handleClearCart = async () => {
    if(cartId) {
      try {
        const response = await apiServices.clearCart({
          id: cartId,
        });

        if (response) {
          toast.error('This merchant info is updated, please try again later.', {
            theme: 'light',
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
          
          setTimeout(() => {
            window.location = '/';
          }, 500);
        }
      } catch (ex) {
      };
    } else {
      toast.error('This merchant info is updated, please try again later.', {
        theme: 'light',
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
      
      setTimeout(() => {
        window.location = '/';
      }, 500);
    }
  }

  // handle 401
  if (authError) {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('login');
    setTimeout(() => {
      window.location = '/';
    }, 0);
  }

  // handle 400
  if(badReqError) {
    handleClearCart()
    localStorage.removeItem('outletId')
  }

  // handle 429
  if(manyAttemptsError) {
    toast.error('Too many attempts, please try again later.', {
      theme: 'light',
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
    });
  }

  // handle 500
  if (unexpectedError) {
    toast.error('Oops! Something went wrong unexpectedly. Please contact support for assistance.', {
      theme: 'colored',
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
    });
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(null, handleErrorResponse);

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  };
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
};

export default httpService
