//lib

import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import { openChangeCartModal } from "../../../redux/reducer/modalReducer";
import { Skeleton } from "primereact/skeleton";

//assets

//reducers

//components

export default function StoreHeader({ store, shipping, outlet, loading }) {
  const dispatch = useDispatch();

  function formattedTime(time) {
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;

    return `${hour12}:${minutes} ${period}`;
  }

  const handleChangeCart = () => {
    dispatch(openChangeCartModal());
  };

  return (
    <div className="store-banner">
      <div className="flex justify-between items-center">
        <label className="_label --store-name flex items-center">
          {!loading ? (
            <label>
              " <span>{outlet.display_name ?? ""}</span> "
            </label>
          ) : (
            <Skeleton width="20em" height="1.8em" borderRadius="1em" />
          )}
          {/* &nbsp;-&nbsp; 
                    {!loading ? 
                        <span>{outlet.located_at ?? "The School"} </span>
                    : 
                        <Skeleton
                            width="15em"
                            height='1.8em'
                            borderRadius="1em"
                        />  
                    } */}
        </label>
        <label
          className="pointer _label --change"
          onClick={() => handleChangeCart()}
        >
          Change
          <Icon
            icon="material-symbols:change-circle-outline"
            color={"#fdfdff"}
            width={18}
            height={18}
          />
        </label>
      </div>
      <div className="flex items-center gap-4 p-0">
        <label className="_label --store-tag">
          <Icon
            icon="line-md:moon-alt-to-sunny-outline-loop-transition"
            color={"#F9A01B"}
            width={20}
            height={20}
          />
          {!loading ? (
            `${outlet.open_at ? formattedTime(outlet.open_at) : ""} - ${
              outlet.close_at ? formattedTime(outlet.close_at) : ""
            }`
          ) : (
            <Skeleton width="10em" height="1.2em" borderRadius="1em" />
          )}
        </label>
        <label className="_label --store-tag">
          <Icon
            icon="tabler:hand-click"
            color={"#F9A01B"}
            width={20}
            height={20}
          />
          {shipping === "Pickup" ? "Self Pickup" : shipping}
        </label>
      </div>
    </div>
  );
}
