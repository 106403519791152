//lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { closeRiderContactModal, openLoginModal } from "../../../redux/reducer/modalReducer";
import FullModal from "../../../components/modal/full.modal.box";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import scrollToTop from "../../../helpers/scroll.to.top";
import { Form, Formik } from "formik";
import IconButton from "../../../components/common/icon.button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import { FullLogo } from "../../../assets/images";
import * as Yup from "yup";
import { setLoginUsername } from "../../../redux/reducer/loginReducer";
import apiServices from "../../../services/api.services";

//assets

//reducers

//components

export default function RiderContact() {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();
    const { riderContactForm } = useSelector((state) => state.checkout);
    const { isOpenRiderContactModal } = useSelector((state) => state.modal);

    const useSchema = Yup.object({
        phone_no: Yup.string()
          .required("Phone number is required")
          .matches(
            /^(1)[0-9]{8,9}$/,
            "Enter a valid international phone number (e.g., 123456789)"
        ),
    });

    const backButton = () => {
        dispatch(closeRiderContactModal())
    }

    const handleSubmit = async({ values, setFieldError }) => {  
        try {
            const response = await apiServices.verifyUserContact({
                username: `60${values.phone_no}`,
            });
            if (response) {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('username', `60${values.phone_no}`)
                localStorage.setItem('userId', response.data.id)
                localStorage.setItem('login', 'partial')
                dispatch(closeRiderContactModal())
            }
        } catch (ex) {
            if (ex && ex.response.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        setFieldError(item, errors[item]);
                    });
                }
            }
        };
    }

    useEffect(() => {
        setTop(true);
    }, [isOpenRiderContactModal]);
      
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);

    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenRiderContactModal}
            backButton={backButton}
            screenSize={"xl"}
            dialogClassName="--margin"
            contentClassName="--short"
            content={
                <>
                    <MDBModalBody ref={modalRef}>
                        <Formik
                            initialValues={riderContactForm}
                            validationSchema={useSchema}
                            enableReinitialize={true}
                            onSubmit={(values, { errors, setFieldError }) => {
                                handleSubmit({ values, errors, setFieldError });
                            }}
                        >
                            {({
                                setFieldValue,
                                isSubmitting,
                                handleReset,
                                isValid,
                                errors,
                                values,
                                handleSubmit,
                            }) => (
                                <Form>
                                    <div className="flex justify-end pe-4">
                                        <IconButton
                                            icon="akar-icons:cross" 
                                            width="18" 
                                            height="20" 
                                            onClick={() => backButton()}
                                        />
                                    </div>
                                    <MDBContainer className="rider-contact-container"> 
                                        <MDBRow className="flex justify-center">
                                            <div className="image-frame --logo">
                                                <LazyLoadImage
                                                    src={FullLogo} // || picture
                                                    alt="..."
                                                />
                                            </div>
                                        </MDBRow>
                                        <MDBRow className="provide-row">
                                            <label className="_label --rider-contact-desc text-center">
                                                Please provide valid number for us to contact.
                                            </label>
                                        </MDBRow>
                                        <div className="p-2">
                                            <Input
                                                type="number"
                                                name="phone_no"
                                                label="Phone Number"
                                                placeholder="123456789"
                                                className="mb-4"
                                                isRequired={true}
                                                as="line-field"
                                                inputClassName="phone_no"
                                            />          
                                        </div>

                                    <div className="mt-2 button-container --notFixed"> 
                                        <Button type="submit" className="w-100" disabled={!isValid || isSubmitting} >LOG IN / SIGN UP</Button>
                                    </div>
                                    </MDBContainer>
                                </Form>
                            )}
                        </Formik>
                    </MDBModalBody>
                </>
            }
        />
    );
}