//lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeBankModal,
  openBankModal,
  openReceiptModal,
} from "../../../redux/reducer/modalReducer";
import scrollToTop from "../../../helpers/scroll.to.top";
import HalfModal from "../../../components/modal/half.modal.box";
import { Icon } from "@iconify/react";
import RadioButton from "../../../components/element/radio.button";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import ModalHeader from "../../../components/header/modal.header";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import apiServices from "../../../services/api.services";
import { useNavigate } from "react-router-dom";
import {
  clearBankList,
  getBankList,
} from "../../../redux/reducer/checkoutReducer";
import IconButton from "../../../components/common/icon.button";
import { setFullPageLoading } from "../../../redux/reducer/commonReducer";
import { toast } from "react-toastify";
import { Skeleton } from "primereact/skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets

//reducers

//components

export default function Bank({ banks }) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  
  const { outletId } = useSelector((state) => state.common);
  const { isOpenBankModal } = useSelector((state) => state.modal);
  const { cartInfo, providerList, bankList, bankListLoading, providerListLoading } = useSelector(
    (state) => state.checkout
  );

  const [top, setTop] = useState();
  const [activeBank, setActiveBank] = useState(null);
  const [providerId, setProviderId] = useState(null);

  const backButton = () => {
    dispatch(closeBankModal());
  };

  const handleChooseBank = (tenant_id, provider_id, name) => {
    setActiveBank(name);

    setTimeout(async () => {
      try {
        scrollToTop()
        dispatch(closeBankModal());
        dispatch(setFullPageLoading(true));
        const response = await apiServices.generatePayment({
          tenant_channel_id: tenant_id,
          provider_channel_id: provider_id !== null ? provider_id : '',
          cart_id: cartInfo.cart_id,
        });

        if (response) {
          setActiveBank("");
          let link = response.data.result.redirectUrl
            ? response.data.result.redirectUrl
            : response.data.url;

          if (link) {
            window.location.replace(link);
          } else {
            dispatch(openBankModal());
            dispatch(setFullPageLoading(false));
            toast.error(
              "Oops! Something went wrong unexpectedly. Please try other payment methods.",
              {
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              }
            );
          }
        }
      } catch (ex) {
        setActiveBank("");
        dispatch(setFullPageLoading(false));
        if (ex && Object.keys(ex).length > 0) {
          let errorMsg = [];
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                errorMsg = [errors[item]];
                // setFieldError(item, errors[item]);
                toast.error(
                  "Oops! Something went wrong unexpectedly. Please try other payment methods.",
                  {
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  }
                );
              });
            }
          }
        }
      }
    }, 0);
  };

  useEffect(() => {
    setTop(true);
  }, [isOpenBankModal]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  return (
    <>
      <HalfModal
        centered
        scrollable
        staticBackdrop={true}
        show={isOpenBankModal}
        backButton={backButton}
        desktopModal={width >= 991 ? true : false}
        screenSize={width >= 991 ? "xl" : ""}
        className="bank-modal"
        content={
          <>
            <MDBModalBody ref={modalRef}>
              <MDBContainer className="bank-container">
              <div className="select-bank mt-4 flex justify-between items-center gap-5">
                <div>
                    <IconButton
                      icon="solar:arrow-left-linear"
                      width="25"
                      height="25"
                      className={`--blue pt-3 ps-3`}
                      onClick={() => backButton()}
                    />
                  </div>
                  <div
                    className={`w-100 text-start
                    ${ bankList && bankList.length > 0 ? "" : "mb-3 mt-3"}   
                    `}
                  >
                    Select Bank
                  </div>
                <div />
              </div>
                <MDBRow>
                  {bankListLoading || providerListLoading ?
                    Array.from({ length: 3 }, (_, storeDetailsIndex) => (
                      <Skeleton
                        width="100%"
                        height="60px"
                        borderRadius="10px"
                        className="mt-4 mb-2"
                      />
                    ))
                  : 
                    <>
                      {bankList?.length > 0 &&
                      bankList.map((bank, bankIndex) => (
                        <div
                          key={bankIndex}
                          className={`flex justify-between pointer items-center bank-tab gap-4  
                          ${
                            bank.name === activeBank
                              ? "--active"
                              : ""
                          }`}
                          onClick={() => handleChooseBank(bank.tenant_channel_id, bank.provider_channel_id, bank.name)}
                        >
                          <Icon
                            icon={
                              bank.name === activeBank
                                ? "ph:circle-fill"
                                : "ph:circle"
                            }
                            width={26}
                            height={26}
                            color={
                              "#F9A01B"
                            }
                          />
                          <div className="w-100 text-start">
                            {bank.display_name}
                          </div>
                          {bank.image_url ? 
                            <div className="image-frame --bank ms-3">
                              <img 
                                src={bank.image_url}
                                alt="..." 
                              />
                            </div>
                          :
                            <div className="bank-circle">
                              <Icon icon="bxs:bank" width={26} height={26} />
                            </div>
                          }
                        </div>
                      ))}
                    </>
                  }
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </>
        }
      />
    </>
  );
}
