import { useEffect, useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function OrderSteps({ order }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const delay = 200;
  
      const timeoutId = setTimeout(() => {
        setProgress((order.order_status === '4' && order.delivery_status === 'COMPLETED') ||  order.order_status === '5' || (order.order_status === '4' && order.method === 'Self Pick') ? 100 
        : (order.order_status !== '2' && order.delivery_status !== 'REJECTED') || order.delivery_status === 'ON_GOING' || order.delivery_status === 'PICKED_UP' ? 51 
        : 0);
      }, delay);
  
      return () => clearTimeout(timeoutId);
    }, [order.order_status, order.delivery_status]);

    return (
      <MDBRow className="mt-3">
        <MDBContainer className="steps-container">
            <ProgressBar
                percent={progress}
                filledBackground="linear-gradient(to right, #ffe5be85, #f9a01b)"
            >
                <Step>
                {({ accomplished }) => (
                    <div className="steps-circle --first">
                        <Icon
                            icon={
                                (order.order_status !== '2' && order.delivery_status !== 'REJECTED') || order.delivery_status === 'ON_GOING' || order.delivery_status === 'PICKED_UP' ? "charm:tick"
                                : order.order_status === '2' || order.delivery_status === 'REJECTED' ? "ph:circle-fill" 
                                : ''
                            }
                            color={"#F9A01B"} 
                            width={20} 
                            height={20} 
                        />
                    </div>
                )}
                </Step>
                <Step 
                    transition={(order.order_status !== '2' && order.delivery_status !== 'REJECTED') || order.delivery_status === 'ON_GOING' || order.delivery_status === 'PICKED_UP' ? "scale" : "" }
                >
                {({ accomplished }) => (
                   order.method === "Delivery" && <div className="steps-circle --second">
                        <Icon
                            icon={
                                (order.order_status === '4' && order.delivery_status === 'COMPLETED') ||  order.order_status === '5' ? "charm:tick"
                                : (order.order_status !== '2' && order.delivery_status !== 'REJECTED') || order.delivery_status === 'ON_GOING' || order.delivery_status === 'PICKED_UP' ? "ph:circle-fill" 
                                : ''
                            }
                            color={"#F9A01B"} 
                            width={20} 
                            height={20} 
                        />
                    </div>
                )}
                </Step>
                <Step transition={(order.order_status === '4' && order.delivery_status === 'COMPLETED') ||  order.order_status === '5' || (order.order_status === '4' && order.method === 'Self Pick') ? "scale" : "" }>
                {({ accomplished }) => (
                    <div className="steps-circle --third">
                        <Icon
                            icon={
                                (order.order_status === '4' && order.delivery_status === 'COMPLETED') || (order.order_status === '4' && order.method === 'Self Pick') ? "charm:tick"
                                : order.order_status === '5' ? "charm:cross"
                                : ''
                            }
                            color={"#F9A01B"} 
                            width={20} 
                            height={20} 
                        />
                    </div>
                )}
                </Step>
            </ProgressBar>
            <div className="steps-desc-row">
                <div className="steps-desc --first">Preparing</div>
                {order.method === "Delivery" && <div className="steps-desc --second">On The Way</div>}
                <div className="steps-desc --third">{order.order_status === '5' ? 'Cancelled' : 'Completed'}</div>
            </div>
        </MDBContainer>
      </MDBRow>
    );
}