//lib
import { MDBRow } from "mdb-react-ui-kit";
import Lottie from "lottie-react";
import { EmptyCart, PaymentLoading, PaymentLoading2 } from "../../assets/lotties";
import useWindowDimensions from "../hook/use.window.dimensions";

//assets

//reducers

//components

export default function FullPageLoading() {
  const { width } = useWindowDimensions();

  return (
    <div className="full-page-loading">
        <div className="lottie-frame d-grid justify-content-center">
            <Lottie animationData={PaymentLoading} />
        </div>
        <div className="loading-label text-center">
            You will be redirected to payment gateway soon..
            <br />
            <label className="mt-3">Please <span>DO NOT</span> close this page or press refresh or back button.</label>
        </div>
    </div>
  );
}
