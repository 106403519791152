//lib
import { MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets
import { Delivery, Pickup } from "../../../assets/images";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//components
import PromoCarousel from "./promo.carousel";
import FireworkEffect from "../../../components/common/firework.effect";

export default function OptionHeader({ option, setOption }) {
    const { width } = useWindowDimensions()
    
    const toggleOptions = [
        {label: 'Pickup', value: 'Pickup'},
        {label: 'Delivery', value: 'Delivery'},
    ]

    return (
        <MDBRow className="flex justify-center relative">
            {width > 600 && 
                <div className="mt-5">
                    <PromoCarousel />
                </div>
            }
            <div className="option-toggle">
                {toggleOptions.map((toggle) => (
                    <div 
                        className={`toggle-tab ${option === toggle.value ? '--active' : ''} pointer`}
                        onClick={() => setOption(toggle.value)}
                    >
                        <div className="flex justify-center">
                            <div className="image-frame --toggle">
                                <LazyLoadImage src={toggle.label === 'Pickup' ? Pickup : Delivery} alt="..." />
                            </div>
                        </div>
                        <label 
                            className={`_label --option-label pointer
                            ${option === toggle.value ? 'active' : ''} `}
                        >
                            {toggle.label}
                        </label>
                    </div>
                ))}
            </div>
            {/* <FireworkEffect /> */}
        </MDBRow>
    );
}
