//lib
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";
import { useDispatch } from "react-redux";
import { openLoginModal, openPurchaseHistoryModal } from "../../redux/reducer/modalReducer";
import { getPurchaseHistory, setBackPurchaseList } from "../../redux/reducer/orderReducer";
import { useNavigate } from "react-router-dom";

//assets

//reducers

//components

export default function PageHeader(props) {
    const navigate = useNavigate()

    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    const userId = localStorage.getItem("userId");
    
    const handleRedirect = () => {
      navigate(`/order?open=purchase_history`)
    }

    const handleLogout = () => {
      localStorage.clear();
      navigate(`/`)
    }

    const {
      icon,
      subtitle,
      type,
      redirect
    } = props;
    return (

      <> 
          <MDBRow className='common _page-header'>
            <label className={type ? 'flex justify-between' : ''}>
              <label>
                {props.children}&nbsp; 
                {(process.env.REACT_APP_ENV === 'development' || 
                  process.env.REACT_APP_ENV === 'local') && 
                  (token && userId || token && username) && 
                  type &&
                  <Icon
                    icon="material-symbols:logout"
                    color={"#fdfdff"} 
                    width={24} 
                    height={24} 
                    className="pointer ms-2"
                    onClick={() => handleLogout()}
                  />
                }
              </label>
              <Icon
                icon={icon}
                color={"#F9A01B"} 
                width={24} 
                height={24} 
                className="pointer"
                onClick={() => redirect ? handleRedirect() : undefined}
              />
            </label>
            <div>{subtitle}</div>
          </MDBRow>
      </>
    );
  }