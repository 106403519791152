import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
    loginForm: {
        username: '',
        verification_code: '',
        terms_condition: false
    },
    loginUsername: ''
};

const dataSlice = createSlice({
  name: "login",
  initialState: initialData,
  reducers: {
    setLoginUsername: (state, action) => {
      state.loginUsername = action.payload;
    },
  },
  extraReducers: {
  },
});

export const {
  setLoginUsername
} = dataSlice.actions;

export default dataSlice.reducer;

