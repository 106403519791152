import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const getCartInfo = createAsyncThunk('getCartInfo', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getCartInfo(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const clearCart = createAsyncThunk('clearCart', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.clearCart(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})
  
export const updateCart = createAsyncThunk('updateCart', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.updateCart(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const getProviderList = createAsyncThunk('getProviderList', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getProviderList(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const getCartCount = createAsyncThunk('getCartCount', async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getCartCount(payload)
      return response
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
})

export const getBankList = createAsyncThunk('getBankList', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getBankList(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getAllPayments = createAsyncThunk('getAllPayments', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getAllPayments(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const initialData = {
    carts: [
        {
            "menu_id": 2,
            "menu_name": "6 Pcs “Door Gift”",
            "cart_item_id": 1,
            "menu_price": 30.90,
            "quantity": 1,
            "quantity_balance": null,
            "remark": null,
            "ticket_expired_period": 0,
            "day_count_start_from_order": null,
            "details": [
                {
                    "option_id": 1,
                    "option_item_id": 3,
                    "name": "Choco Marsh"
                },
                {
                    "option_id": 2,
                    "option_item_id": 2,
                    "name": "Kit Kat Kitty"
                }
            ]
        }
    ],
    cartCounts: 0,
    bankList: [
        // {
        //     "name": "RHB",
        //     "value": "RHB001"
        // },
        // {
        //     "name": "Maybank",
        //     "value": "M2U001"
        // },
        // {
        //     "name": "CIMB",
        //     "value": "CIMB001"
        // },
        // {
        //     "name": "Public Bank",
        //     "value": "PB001"
        // }
    ],
    timeMenu: [
        // {
        //     label: 'ASAP',
        //     value: '',
        // },
        // {
        //     label: '9:30',
        //     value: '9:30',
        // },
        // {
        //     label: '10:00',
        //     value: '10:00',
        // },
        // {
        //     label: '10:30',
        //     value: '10:30',
        // },
        // {
        //     label: '11:00',
        //     value: '11:00',
        // },
        // {
        //     label: '11:30',
        //     value: '11:30',
        // },
    ],
    riderContactForm: {
        phone_no: ''
    },
    checkoutForm: {
        remark: ''
    },
    editCartData: [],
    providerList: [],
    cartLoading: false,
    limitedError: false,
    bankListLoading: false,
    providerListLoading: false,
    addCartLoading: false,
    updateCartLoading: false,
};

const dataSlice = createSlice({
  name: "checkout",
  initialState: initialData,
  reducers: {
    setEditCartData: (state, action) => {
      state.editCartData = action.payload;
    },
    setAddCartLoading: (state, action) => {
      state.addCartLoading = action.payload;
    },
    setUpdateCartLoading: (state, action) => {
      state.updateCartLoading = action.payload;
    },
    setLimitedError: (state, action) => {
      state.limitedError = action.payload;
    },
    cartInfo: (state, action) => {
        state.carts = action.payload;
    },
    clearBankList: (state, action) => {
      state.bankList = [];
    },
    handleChangeQuantity: (state, action) => {
        if(action.payload.type == "increases"){
          for (let i = 0; i <  state.listing.length; i++) {
            if(i == action.payload.index ) {
              void(state.listing[action.payload.index] =
                {...state.listing[action.payload.index],
              quantity:state.listing[action.payload.index].quantity + 1
              } )
            }
          }

        }
        else if(action.payload.type == "decreases")
      for (let i = 0; i <  state.listing.length; i++) {
        if(i == action.payload.index ) {
          void(state.listing[action.payload.index] =
            {...state.listing[action.payload.index],
          quantity:state.listing[action.payload.index].quantity - 1
          } )
        }
      }
    },
  },
  extraReducers: {
    [getCartInfo.pending]: (state, action) => {
        state.cartLoading = true
    },
    [getCartInfo.fulfilled]: (state, action) => {
        state.cartLoading = false
        state.cartInfo = action.payload.data.carts[0]
        state.timeMenu = action.payload.data.schedule_time
    },
    [getCartInfo.rejected]: (state, action) => {
        state.cartLoading = false
    },

    [getCartCount.pending]: (state, action) => {
      state.loading = true
    },
    [getCartCount.fulfilled]: (state, action) => {
        state.loading = false
        state.cartCounts = action.payload.data
    },
    [getCartCount.rejected]: (state, action) => {
        state.loading = false
    },

    // [getProviderList.pending]: (state, action) => {
    //     state.providerListLoading = true
    // },
    // [getProviderList.fulfilled]: (state, action) => {
    //     state.providerListLoading = false
    //     state.providerList = action.payload.data
    // },
    // [getProviderList.rejected]: (state, action) => {
    //     state.providerListLoading = false
    // },

    // [getBankList.pending]: (state, action) => {
    //     state.bankListLoading = true
    // },
    // [getBankList.fulfilled]: (state, action) => {
    //     state.bankListLoading = false
    //     state.bankList = action.payload.data
    // },
    // [getBankList.rejected]: (state, action) => {
    //     state.bankListLoading = false
    // },

    [getAllPayments.pending]: (state, action) => {
      state.bankListLoading = true
    },
    [getAllPayments.fulfilled]: (state, action) => {
        state.bankListLoading = false
        state.bankList = action.payload.data
    },
    [getAllPayments.rejected]: (state, action) => {
        state.bankListLoading = false
    },
  },
});

export const {
    setAddCartLoading,
    setUpdateCartLoading,
    setEditCartData,
    setLimitedError,
    setCarts,
    handleChangeQuantity,
    clearBankList
} = dataSlice.actions;

export default dataSlice.reducer;

