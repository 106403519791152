import { createSlice } from "@reduxjs/toolkit";
import { 
  PromoOne,
  PromoTwo,
  PromoThree,
  PromoFour,
  PromoFive,
  PromoSix,
  MobilePromoOne,
  MobilePromoTwo,
  MobilePromoThree,
  MobilePromoFour,
  HDImage,
  HDImageJPG,
  MobilePromoSix,
  MobilePromoSeven,
  PromoSeven,
  PromoEight,
  MobilePromoEight,
  PromoNine,
  PromoTen,
  MobilePromoNine,
  MobilePromoTen,
} from "../../assets/images";

export const initialData = {
  policy_item: [
    {
      title: "Personal Information Collection",
      content: "We collect personal information (name, contact details, etc.) when you register on our site, place an order, subscribe to our newsletter, or fill out a form.",
    },
    {
      title: "Use of Information",
      content: "Information collected is used for order processing, providing customer service, improving our website, and, if opted-in, sending periodic promotional emails.",
    },
    {
      title: "Data Protection",
      content: "We implement a variety of security measures to maintain the safety of your personal information.",
    },
    {
      title: "Cookies",
      content: "Our website may use cookies to enhance user experience and track website visits.",
    },
    {
      title: "Information Sharing",
      content: "We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties except for trusted third parties who assist us in operating our website, conducting our business, or servicing you.",
    },
    {
      title: "Third-Party Links",
      content: " Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate privacy policies.",
    },
    {
      title: "Consent",
      content: "By using our site, you consent to our privacy policy.",
    },
    {
      title: "Changes to Policy",
      content: "Any changes to our privacy policy will be posted on this page.",
    },
  ],
  tc_item: [
    {
      title: "Introduction",
      content: "These Terms and Conditions govern your use of Memorie Tart's website and services. By accessing this website, you agree to these terms in full.",
    },
    {
      title: "Use of Website",
      content: "The website is provided for personal, non-commercial use only. Any misuse or unauthorized modification of the website's content is strictly prohibited.",
    },
    {
      title: "User Accounts",
      content: "Users are responsible for maintaining the confidentiality of their account and password and for all activities that occur under their account.",
    },
    {
      title: "Product Purchases",
      content: "All purchases made through the website are subject to product availability and the company's return policy.",
    },
    {
      title: "Referral Program",
      content: "Participation in the referral program is governed by the specific terms and conditions of the referral program.",
    },
    {
      title: "Intellectual Property",
      content: "The content, layout, design, data, graphics and products on this website are protected by intellectual property laws.",
    },
    {
      title: "Liability Limitation:",
      content: "Memorie Tart is not liable for any indirect, special, or consequential loss; or any business losses, loss of revenue, income, profits, or anticipated savings.",
    },
    {
      title: "Changes to Terms",
      content: "Memorie Tart reserves the right to modify these terms at any time. Your continued use of the site signifies your acceptance of any adjustments.",
    },
  ],
  referral_item: [
    {
      title: "Eligibility",
      content: "The Memorie Tart Referral Program is open to selected individuals as determined by Memorie Tart. If you are interested in participating and have not been directly invited, please reach out to us at +60 12-257 4848 for consideration.",
    },
    {
      title: "Referral Code",
      content: "Each user that eligible will receive a unique referral code. This code can be shared with friends and family.",
    },
    {
      title: "Discounts",
      content: "When a new customer uses your referral code on their first purchase, they will receive a certain discount % on their order.",
    },
    {
      title: "Referral Rewards",
      content: "For each successful referral (where the referred person completes a purchase using your code), you will earn a commission of certain percentage of their purchase amount.",
    },
    {
      title: "Withdrawal Process for Commissions",
      content: "Commissions earned through the referral program will be held in your account. To withdraw your earnings, follow the withdrawal process outlined on our website. Please note that withdrawals are subject to verification and may take up to 7 days to process.",
    },
    {
      title: "Restrictions",
      content: "Referral codes must be used for genuine referrals only. Misuse of the code, will result in disqualification from the program and possible account suspension.",
    },
    {
      title: "Changes to Terms",
      content: "Memorie Tart reserves the right to change the terms and conditions of the referral program at any time without prior notice.",
    },
  ],
  promotion_item: [
    {
      image: PromoNine,
      alt: "...",
    },
    {
      image: PromoTen,
      alt: "...",
    },
    // {
    //   image: PromoFour,
    //   alt: "...",
    // },
    // {
    //   image: PromoFive,
    //   alt: "...",
    // },
    // {
    //   image: PromoSix,
    //   alt: "...",
    // }
  ],
  mobile_promotion_item: [
    {
      image: MobilePromoNine,
      alt: "...",
    },
    {
      image: MobilePromoTen,
      alt: "...",
    },
    // {
    //   image: MobilePromoSeven,
    //   alt: "...",
    // },
    // {
    //   image: MobilePromoThree,
    //   alt: "...",
    // },
    // {
    //   image: MobilePromoFour,
    //   alt: "...",
    // },
    // {
    //   image: PromoFive,
    //   alt: "...",
    // },
    // {
    //   image: PromoSix,
    //   alt: "...",
    // }
  ],
  navigation_item: [
    {
      label: "Menu",
      path: "/menu",
      icon: "line-md:check-list-3",
      active_icon: "line-md:check-list-3-twotone",
      count: false,
    },
    {
      label: "Cart",
      path: "/cart",
      icon: "solar:cart-3-line-duotone",
      active_icon: "solar:cart-3-bold",
      count: true,
    },
    {
      label: "Order",
      path: "/order",
      icon: "fluent:receipt-cube-24-regular",
      active_icon: "fluent:receipt-cube-24-filled",
      count: false,
    }
  ],
  footerListing: [
    {
      id: 1,
      title: "Service",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [],
    },
    {
      id: 2,
      title: "About",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [],
    },
    {
      id: 3,
      title: "Support",
      active: false,
      // icon: 'ic:outline-keyboard-arrow-down',
      item: [
        // {
        //   label: "Support",
        //   to: "",
        // },
      ],
    }
  ],
  userId: localStorage.getItem("userId") || "",
  shipping: localStorage.getItem("shipping") || "Pickup",
  token: localStorage.getItem("token") || "",
  username: localStorage.getItem("username") || "",
  deliveryAddressId: localStorage.getItem("deliveryAddressId") || "",
  outletId: localStorage.getItem("outletId") || "",
  fullPageLoading: false,
  currentLocation: {
    lat: localStorage.getItem("lat"),
    lng: localStorage.getItem("lng"),
  },
  confirmationItem: null
};

const dataSlice = createSlice({
  name: "common",
  initialState: initialData,
  reducers: {
    setToken: (state, action) => {
      state.token = localStorage.getItem("token");
    },
    setUsername: (state, action) => {
      state.username = localStorage.getItem("username");
    },
    setShipping: (state, action) => {
      state.shipping = localStorage.getItem("shipping");
    },
    setDeliveryAddressId: (state, action) => {
      state.deliveryAddressId = localStorage.getItem("deliveryAddressId");
    },
    setOutletId: (state, action) => {
      state.outletId = localStorage.getItem("outletId");
    },
    setUserId: (state, action) => {
      state.userId = localStorage.getItem("userId");
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation.lat = action.payload.latitude;
      state.currentLocation.lng = action.payload.longitude;
    },
    setFullPageLoading: (state, action) => {
      state.fullPageLoading = action.payload;
    },
    setConfirmationItem: (state, action) => {
      state.confirmationItem = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setToken,
  setUsername,
  setShipping,
  setDeliveryAddressId,
  setOutletId,
  setUserId,
  setCurrentLocation,
  setFullPageLoading,
  setConfirmationItem
} = dataSlice.actions;

export default dataSlice.reducer;
