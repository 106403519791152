//lib

import { Icon } from "@iconify/react";
import { Field, Form, Formik } from "formik";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import {
  openBankModal,
  openCartModal,
  openChangeCartModal,
  openReOrderModal,
  openRiderContactModal,
  openSetAddressModal,
} from "../../../redux/reducer/modalReducer";
import Input from "../../../components/element/input";
import ActionMenu from "../../../components/element/action.menu";
import InputCount from "../../../components/element/input.count";
import {
  setConfirmationItem,
  setDeliveryAddressId,
  setUsername,
} from "../../../redux/reducer/commonReducer";
import { useEffect, useState } from "react";
import {
  clearBankList,
  deleteCart,
  getAllPayments,
  getBankList,
  getCartCount,
  getCartInfo,
  getProviderList,
  getProviderToken,
  setEditCartData,
  updateCart,
} from "../../../redux/reducer/checkoutReducer";
import { toast } from "react-toastify";
import { getMenuInfo, setQuantity } from "../../../redux/reducer/menuReducer";
import apiService from "../../../services/api.services";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DefaultCenter, Driver, EmptyCart, Rider } from "../../../assets/images";
import Lottie from "lottie-react";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { OrderLoading } from "../../../assets/lotties";

//assets

//reducers

//components

export default function CheckoutForm({ outlet, cart }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addressList, addressListLoading } = useSelector((state) => state.store);
  const { deliveryAddressId, outletId } = useSelector((state) => state.common);
  const { carts, timeMenu, handleChangeQuantity, providerToken, cartLoading, limitedError, addCartLoading, updateCartLoading, editCartData } =
    useSelector((state) => state.checkout);

  const [id, setId] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("CAR");
  const [pickupTime, setPickupTime] = useState("");
  const [orderLoading, setOrderLoading] = useState(false);

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const userId = localStorage.getItem("userId");
  const shipping = localStorage.getItem("shipping");
  const invitationCode = localStorage.getItem("invitationCode");
  const login = localStorage.getItem("login");
  const savedAddresses =  JSON.parse(localStorage.getItem("savedAddresses"));

  const currentDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}`;

    return formattedDateTime;
  };

  const checkoutForm = {
    cart_id: cart?.cart_id,
    remark: "",
    invitation_code: invitationCode !== null ? invitationCode : "",
    delivery_method: "CAR",
    //deliveryMethod ? deliveryMethod : "",
    // latitude: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.lat,
    // longitude: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.lng,
    // address: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.address,
    // name: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.name,
    // delivery_remark: addressList?.find(
    //   (address) => address.id === deliveryAddressId
    // )?.notes,
    // unit_no: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.unit_no,
    // street: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.street,
    // city: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.city,
    // postal_code: addressList?.find(
    //   (address) => address.id === deliveryAddressId
    // )?.postal_code,
    // state: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.state,
    // country: addressList?.find((address) => address.id === deliveryAddressId)
    //   ?.country,
    selfpick_mode: pickupTime ? "scheduled" : "ASAP",
    scheduled_at: pickupTime ? `${currentDate()} ${pickupTime}` : "",
  };

  const handleChangeAddress = () => {
    navigate('/cart?open=set_address');
  };

  const handleSubmitContact = () => {
    dispatch(openRiderContactModal());
  };

  let wordCount = 0;

  const handleRemarkChange = (event, { setFieldValue }) => {
    const remarkText = event.target.value;
    setFieldValue('remark', remarkText)
    wordCount = remarkText.length;
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    if (login && login !== undefined && (login === 'partial' || login === 'full')) {
      
      if(values.cart_id && values.cart_id !== undefined) {
        setOrderLoading(true)

        try {
          // dispatch(clearBankList());
          const response = await apiService.createOrder(
            shipping === "Delivery"
              ? {
                  cart_id: values.cart_id,
                  remark: values.remark,
                  invitation_code: values.invitation_code,
                  delivery_method: values.delivery_method,
                  // latitude: values.latitude,
                  // longitude: values.longitude,
                  // address: values.address,
                  // name: values.name,
                  // delivery_remark: values.delivery_remark,
                  // unit_no: values.unit_no,
                  // street: values.street,
                  // city: values.city,
                  // postal_code: values.postal_code,
                  // state: values.state,
                  // country: values.country,
                  address_id: deliveryAddressId,
                }
              : {
                  cart_id: values.cart_id,
                  remark: values.remark,
                  invitation_code: values.invitation_code,
                  selfpick_mode: values.selfpick_mode,
                  scheduled_at: values.scheduled_at,
                }
          );

          if (response) {
            // dispatch(
            //   getBankList({
            //     id: process.env.REACT_APP_ENV === "production" ?  '18' : '28',
            //     merchant_id: outletId,
            //   })
            // )
            dispatch(
              getAllPayments({
                merchant_id: outletId,
              })
            )
              .unwrap()
              .then((res) => {
                setOrderLoading(false)
                if (res.data?.length === 0 || res.data === null) {
                  toast.error(
                    "No available payment methods due to third party payment gateway issue, please contact IT support.",
                    {
                      theme: "light",
                      hideProgressBar: true,
                      closeOnClick: true,
                      closeButton: false,
                    }
                  );
                } else {
                  dispatch(openBankModal());
                }
              })
              .catch((ex) => {
                setOrderLoading(false)
                if (ex && ex.response.status === 422) {
                  const errors = ex.response.data.errors;
                  if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                      // setFieldError(item, errors[item]);
                    });
                  }
                  toast.error(
                    "No available providers due to third party payment gateway issue, please contact IT support.",
                    {
                      theme: "light",
                      hideProgressBar: true,
                      closeOnClick: true,
                      closeButton: false,
                    }
                  );
                }
              });
          }
        } catch (ex) {
          setOrderLoading(false)
          if (ex && Object.keys(ex).length > 0) {
            let errorMsg = [];
            if (ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  errorMsg = errors[item][0];
                });
                toast.error(errorMsg,
                  {
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  }
                );
              }
            }
          }
        }
      } else {
        dispatch(
          getCartInfo({
            address_id: deliveryAddressId,
            invitation_code: invitationCode !== null ? invitationCode : "",
            delivery_method: "CAR"
          })
        )
        .catch((ex) => {
          if (ex && ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                localStorage.removeItem('invitationCode')
                toast.error(
                  errors[item][0], {
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  }
                );
              });
            }
          }
        })
      }
    } else {
      handleSubmitContact();
    }
  };

  const handleEditCartDetails = (id, cart_item) => {
    dispatch(setEditCartData(cart_item))
    dispatch(openCartModal());
  };

  const handleOpenConfirmation = (id) => {
    dispatch(openChangeCartModal())
    dispatch(setConfirmationItem(id))
  }

  useEffect(() => {
    if(!addressListLoading) {
      if(shipping === "Delivery" 
      && addressList?.find((address) => address.id === parseInt(deliveryAddressId)) === undefined
      && addressList?.find((address) => address.id === parseInt(deliveryAddressId))?.name === null ) {
        navigate('/cart?open=set_address')
        toast.error("Please select an address from you address book.", {
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      }
    }
  }, [addressList]);

  useEffect(() => {
    dispatch(setDeliveryAddressId());
  }, []);

  useEffect(() => {
    dispatch(
      getCartInfo({
        address_id: deliveryAddressId,
        invitation_code: invitationCode !== null ? invitationCode : "",
        delivery_method: "CAR"
      })
    )
    .catch((ex) => {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            localStorage.removeItem('invitationCode')
            toast.error(
              errors[item][0], {
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              }
            );
          });
        }
      }
    })
  }, [deliveryMethod, deliveryAddressId]);

  return (
    <Formik
      initialValues={checkoutForm}
      // validationSchema={useSchema}
      enableReinitialize={true}
      onSubmit={(values, { errors, setFieldError }) => {
        handleSubmit({ values, errors, setFieldError });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            <div className="flex mt-5 gap-4">
              <label className="_label --tag">
                <div data-tooltip-id="info-tooltip">
                  <Icon
                    icon="ic:outline-info"
                    color={"#fdfdff"}
                    width={16}
                    height={16}
                    className="pointer mb-1"
                  />
                  <div>
                    <ReactTooltip
                      id="info-tooltip" 
                      effect="solid"
                      place="bottom"
                      className="custom-tooltip relative"
                    >
                      Shipping method
                    </ReactTooltip>
                  </div>
                </div>
                {shipping === "Pickup" ? "Self Pickup" : "Delivery"}
              </label>
              {shipping === "Pickup" ? (
                <ActionMenu
                  handlePickupTime={setPickupTime}
                  menus={timeMenu}
                  left={12.5}
                >
                  <label className="_label --tag">
                    <Icon
                      icon="mdi:clock-outline"
                      color={"#fdfdff"}
                      width={16}
                      height={16}
                    />
                    Ready at: {pickupTime ? pickupTime : "ASAP"}
                    <Icon
                      icon="octicon:chevron-down-16"
                      color={"#fdfdff"}
                      width={14}
                      height={14}
                      className="pointer"
                    />
                  </label>
                </ActionMenu>
              ) : (
                <label className="_label --tag">
                  <Icon
                    icon="mdi:clock-outline"
                    color={"#fdfdff"}
                    width={16}
                    height={16}
                  />
                  Ready at: ASAP
                </label>
              )}
            </div>
            <div>
              {cart !== undefined ? (
                <>
                  <MDBContainer className="checkout-form-container">
                    <MDBRow>
                      <label className="_label --subtitle mt-5">
                        {shipping === "Pickup" ? "Pickup At" : "Delivery To"}
                      </label>
                      <div className="flex justify-between items-center gap-4 mt-3">
                        <div>
                          <Icon
                            icon={
                              shipping === "Pickup"
                                ? "ph:storefront"
                                : "line-md:map-marker-alt"
                            }
                            color={"#FDFDFF"}
                            width={24}
                            height={24}
                          />
                        </div>
                        <div className="_label --address w-100">
                          {shipping === "Pickup"
                            ? outlet.display_address
                            : addressList?.find((address) =>
                                address.id === parseInt(deliveryAddressId)
                              )
                            ? addressList?.find((address) =>
                                address.id === parseInt(deliveryAddressId)
                              ).address
                            : savedAddresses?.address
                          }
                        </div>
                        {shipping === "Delivery" && (
                          <div
                            className="_label --edit"
                            onClick={() => handleChangeAddress()}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                    </MDBRow>
                    <MDBRow>
                      <label className="_label --subtitle mt-5">
                        Your Order
                      </label>
                      {cart !== undefined && cart.cart?.length > 0 ? (
                        cart.cart.map((cart, cartIndex) => {
                          const quantitySum =
                            cart.cart?.length > 0 &&
                            cart.cart.reduce((sum, currentItem) => {
                              if (currentItem.menu_id === cart.menu_id) {
                                return sum + currentItem.quantity;
                              }
                              return sum;
                            }, 0);

                          return (
                            <MDBContainer key={cartIndex}>
                              <MDBRow
                                className="checkout-listing mt-2"
                              >
                                <MDBCol
                                  className="col-7 col-lg-8 pointer"
                                  onClick={() => handleEditCartDetails(cart.menu_id, cart)}
                                >
                                  <label className="_label --menu-info pointer">
                                    {cart.menu_name}
                                  </label>
                                </MDBCol>
                                <MDBCol className="col-5 col-lg-4">
                                  <div className="food-price justify-end flex items-center">
                                    <label className="_label --menu-info flex items-center">                             
                                      {id === cart.cart_item_id || (updateCartLoading && cart.cart_item_id === editCartData.cart_item_id) ? (
                                        <Skeleton
                                          width="8em"
                                          height="1em"
                                          className="blue-skeleton"
                                        />
                                        ) : (
                                        `RM ${parseFloat(
                                          cart.total_price
                                        ).toFixed(2)}`
                                      )}
                                      <Icon
                                        icon="mdi:delete-circle"
                                        color="#9EBCD8"
                                        height="24"
                                        width="24"
                                        className="pb-1 ms-3 me-2 pointer"
                                        onClick={() =>
                                          handleOpenConfirmation(cart.cart_item_id)
                                        }
                                      />
                                    </label>
                                  </div>
                                </MDBCol>
                                <MDBCol className="col-1"></MDBCol>
                              </MDBRow>
                              <MDBRow className="add-order">
                                <MDBCol className="col-7 col-lg-8 pointer"
                                  onClick={() => handleEditCartDetails(cart.menu_id, cart)}
                                >
                                  {cart.details?.length > 0
                                    ? cart.details.map((item, index) => {
                                        return (
                                          <MDBRow key={index}>
                                            <label className="_label --menu-addon mt-1">
                                              {item.name}
                                            </label>
                                          </MDBRow>
                                        );
                                      })
                                    // : 
                                    //   Array.from({ length: 2 }, (_, storeDetailsIndex) => (
                                    //     <Skeleton
                                    //       width="15em"
                                    //       height="0.9em"
                                    //       className="blue-skeleton mt-3"
                                    //     />
                                    //   ))
                                    : ''
                                    }
                                  {cart.remark && (
                                    <MDBRow>
                                      <label className="_label --menu-addon mt-1">
                                        {cart.remark}
                                      </label>
                                    </MDBRow>
                                  )}
                                </MDBCol>
                                <MDBCol className="col-5 col-lg-4 text-end p-0">
                                  {limitedError && cart.quantity_balance !== null && 
                                    <div className="text-end mt-2">
                                      <span className="_label --limited-span">Only Left {cart.quantity_balance}</span>
                                    </div>
                                  } 
                                  <InputCount
                                    className="--smaller mb-4 me-5"
                                    cartIndex={cartIndex}
                                    setId={setId}
                                    food_id={cart.cart_item_id}
                                    index={null}
                                    quantity={cart.quantity}
                                    setQuantity={setQuantity}
                                    handleChangeQuantity={handleChangeQuantity}
                                    remark={cart.remark}
                                    balance={
                                      cart.quantity_balance !== null
                                        ? cart.quantity_balance
                                        : undefined
                                    }
                                    update_id={editCartData.cart_item_id}
                                    max={null}
                                    quantitySum={quantitySum}
                                  />
                                </MDBCol>
                                <MDBCol className="col-1 p-0"></MDBCol>
                              </MDBRow>
                            </MDBContainer>
                          );
                        })
                      ) : (
                        !addCartLoading && <div className="mt-0 text-center">
                          <div className="lottie-loading flex justify-center">
                            <div>
                              <div className={`lottie-frame ${!cartLoading ? '--empty' : ''} flex justify-center items-center w-100`}>
                                {cartLoading ? 
                                  <div className="image-frame">
                                    <Lottie animationData={OrderLoading} /> 
                                  </div>
                                : 
                                  <div className="image-frame">
                                    <LazyLoadImage
                                      src={EmptyCart}
                                    />
                                  </div>
                                }
                              </div>
                              <div className={`loading-label ${!cartLoading ? '--empty' : ''} text-center`}>
                                {cartLoading ? "Loading.." : "You haven't added anything to your cart!"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {addCartLoading &&
                         <MDBContainer>
                            <MDBRow className="mt-2">
                              <MDBCol className="col-7 col-lg-8">
                                <Skeleton
                                  width="20em"
                                  height="1.2em"
                                  className="blue-skeleton"
                                />
                              </MDBCol>
                              <MDBCol className="col-5 col-lg-4">
                                <div className="justify-end flex items-center">
                                  <label className="flex items-center">                             
                                      <Skeleton
                                        width="10em"
                                        height="1em"
                                        className="blue-skeleton me-2"
                                      />
                                      <Icon
                                        icon="mdi:delete-circle"
                                        color="#9EBCD8"
                                        height="24"
                                        width="24"
                                        className="pb-1 ms-1 me-2"
                                      />
                                  </label>
                                </div>
                              </MDBCol>
                           <MDBCol className="col-1"></MDBCol>
                         </MDBRow>
                         <MDBRow>
                           <MDBCol className="col-7 col-lg-8">
                              <Skeleton
                                width="13em"
                                height="1em"
                                className="blue-skeleton me-2"
                              />
                           </MDBCol>
                           <MDBCol className="col-5 col-lg-4 text-end mt-2">
                              <Skeleton
                                width="92%"
                                height="35px"
                                className="blue-skeleton"
                                borderRadius="8px"
                              />
                           </MDBCol>
                           <MDBCol className="col-1 p-0"></MDBCol>
                         </MDBRow>
                       </MDBContainer>
                      }
                    </MDBRow>
                    {(cart.cart?.length > 0 || addCartLoading) && (
                      <>
                        <MDBRow>
                          <label className="_label --subtitle mt-5">
                            Special Remarks on your order
                          </label>
                          <div className="p-3 mt-3">
                            <div className="special-remark">
                              <Input
                                name="remark"
                                inputClassName="special"
                                as="textarea"
                                placeholder="Let us know if you have any special request. 
                                                        e.g. i need a gift candle."
                                maxLength={60}
                                onChange={(e) => handleRemarkChange(e, { setFieldValue })}
                              />
                              <div className="text-end pe-2 pb-2">{wordCount}/60</div>
                            </div>
                          </div>
                        </MDBRow>
                        {shipping === "Delivery" && (
                          <MDBRow>
                            <label className="_label --subtitle mt-5">
                              Selected Rider Type
                            </label>
                            <div className="p- mt-3 flex">
                              {/* <div className="col-6 col-lg-3 pe-2">
                                <div
                                  className={`rider-type ${
                                    deliveryMethod === "" ? "--active" : ""
                                  } text-center`}
                                  onClick={() => setDeliveryMethod("")}
                                >
                                  <div className="flex justify-center">
                                    <div className="image-frame --rider">
                                      <LazyLoadImage src={Rider} alt="..." />
                                    </div>
                                  </div>
                                  <label className="_label --rider-desc">
                                    Fast, too fast cream might fall
                                  </label>
                                </div>
                              </div> */}
                              <div className="col-6 col-lg-3 ps-2">
                                <div
                                  className={`rider-type ${
                                    deliveryMethod === "CAR" ? "--active" : ""
                                  } text-center`}
                                  onClick={() => setDeliveryMethod("CAR")}
                                >
                                  <div className="flex justify-center">
                                    <div className="image-frame --rider">
                                      <LazyLoadImage src={Driver} alt="..." />
                                    </div>
                                  </div>
                                  <label className="_label --rider-desc">
                                    It's slow, but steady to go
                                  </label>
                                </div>
                              </div>
                            </div>
                          </MDBRow>
                        )}
                        <MDBRow>
                          <label className="_label --subtitle mt-5">
                            Purchase Details
                          </label>
                          {/* Subtotal
                          Discount
                          Sst
                          Service
                          Delivery fees
                          Total price */}
                          <MDBContainer>
                            <div className="flex mt-3">
                              <MDBCol className="col-9 p-0">
                                <label className="_label --price-label">
                                  Subtotal
                                  {/* Amount (incl.{cart?.service_charge_percent}%
                                  SST) */}
                                </label>
                              </MDBCol>
                              <MDBCol className="col-3 text-end">
                                <label className="_label --price-data">
                                  {!cartLoading ? (
                                    cart?.subtotal ? (
                                      parseFloat(cart?.subtotal).toFixed(2)
                                    ) : (
                                      0
                                    )
                                  ) : (
                                    <Skeleton
                                      width="8em"
                                      height="1em"
                                      className="blue-skeleton"
                                    />
                                  )}
                                </label>
                              </MDBCol>
                            </div>
                            {cart?.discount && (
                              <div className="flex mt-2">
                                <MDBCol className="col-9 p-0">
                                  <label className="_label --price-label">
                                    Discount (Purchase with Promo Link)
                                  </label>
                                </MDBCol>
                                <MDBCol className="col-3 text-end">
                                  <label className="_label --price-data">
                                    {!cartLoading ? (
                                      cart?.discount ? (
                                        `- ${parseFloat(cart?.discount).toFixed(2)}`
                                      ) : (
                                        0
                                      )
                                    ) : (
                                      <Skeleton
                                        width="8em"
                                        height="1em"
                                        className="blue-skeleton"
                                      />
                                    )}
                                  </label>
                                </MDBCol>
                              </div>
                            )}
                            {cart?.sales_service_tax > 0 && (
                              <div className="flex mt-2">
                                <MDBCol className="col-9 p-0">
                                  <label className="_label --price-label">
                                    SST (6%)
                                  </label>
                                </MDBCol>
                                <MDBCol className="col-3 text-end">
                                  <label className="_label --price-data">
                                    {!cartLoading ? (
                                      cart?.sales_service_tax ? (
                                        parseFloat(
                                          cart?.sales_service_tax
                                        ).toFixed(2)
                                      ) : (
                                        "0.00"
                                      )
                                    ) : (
                                      <Skeleton
                                        width="8em"
                                        height="1em"
                                        className="blue-skeleton"
                                      />
                                    )}
                                  </label>
                                </MDBCol>
                              </div>
                            )}
                            {cart?.service_charge_percent !== 0 && (
                              <div className="flex mt-2">
                                <MDBCol className="col-9 p-0">
                                  <label className="_label --price-label">
                                    Service Charge (
                                    {cart?.service_charge_percent}%)
                                  </label>
                                </MDBCol>
                                <MDBCol className="col-3 text-end">
                                  <label className="_label --price-data">
                                    {!cartLoading ? (
                                      cart?.service_charge ? (
                                        parseFloat(
                                          cart?.service_charge
                                        ).toFixed(2)
                                      ) : (
                                        "0.00"
                                      )
                                    ) : (
                                      <Skeleton
                                        width="8em"
                                        height="1em"
                                        className="blue-skeleton"
                                      />
                                    )}
                                  </label>
                                </MDBCol>
                              </div>
                            )}
                            {shipping === "Delivery" && cart?.delivery_fees && (
                              <div className="flex mt-2">
                                <MDBCol className="col-9 p-0">
                                  <label className="_label --price-label">
                                    Delivery Fee
                                  </label>
                                </MDBCol>
                                <MDBCol className="col-3 text-end">
                                  <label className="_label --price-data">
                                    {!cartLoading ? (
                                      cart?.delivery_fees?.total_price ? (
                                        parseFloat(
                                          cart?.delivery_fees?.total_price
                                        ).toFixed(2)
                                      ) : (
                                        "0.00"
                                      )
                                    ) : (
                                      <Skeleton
                                        width="8em"
                                        height="1em"
                                        className="blue-skeleton"
                                      />
                                    )}
                                  </label>
                                </MDBCol>
                              </div>
                            )}
                          </MDBContainer>
                        </MDBRow>
                      </>
                    )}
                    <div className="flex justify-center">
                      <MDBRow className="button-container --full" style={{ background: 'transparent' }}>
                        <div className="p-3 pb-0">
                          {shipping === "Delivery" && cart.cart?.length > 0 && 
                            cart.duration_delivery && (
                              <div className="estimated-delivery flex items-center justify-center gap-2">
                                <Icon icon="prime:clock" color={"#fdfdff"} />
                                <label className="_label --estimated-time">
                                  Estimated Delivery Time :{" "}
                                  {cart.duration_delivery}
                                </label>
                              </div>
                            )}
                        </div>
                        <Button
                          className="mt-4"
                          type="submit"
                          disabled={!cart.cart?.length > 0 || orderLoading} 
                          // option.quantity_balance !== null &&
                          // option.quantity_balance <= maxfoodQuantity
                          //   ? true
                          //   : false || loading
                          //   ? true
                          //   : false || quantity == 0
                          //   ? true
                          //   : false
                          // }
                          loading={false} //addingOrder
                        >
                          {orderLoading ? 'Placing Order' : 'Total PAY - RM'}{" "}
                          {orderLoading ? '' 
                            : cart.total_price && cart.cart?.length > 0
                            ? parseFloat(cart.total_price).toFixed(2)
                            : 0}
                          {orderLoading && <Icon
                            icon="line-md:loading-twotone-loop"
                            width="25px"
                            height="25px"
                            color="#fdfdff"
                            className="ms-3 mb-1"
                          />}
                        </Button>
                      </MDBRow>
                    </div>
                  </MDBContainer>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
