//service
import http from './http.services'

http.setToken(getToken())

async function login(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/validate_verification_code', payload)
  return data
}

// async function register(payload) {
//   const { data } = await http.post(process.env.REACT_APP_API_URL + '/validate_verification_code', payload)
//   return data
// }

async function sendVerificationCode(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + `/send_verification_code`, payload)
  return data
}

async function logout() {
  localStorage.removeItem('token');
}

function getToken() {
  return localStorage.getItem('token')
}

const authService = {
  login,
  sendVerificationCode,
  logout,
  getToken,
};

export default authService