//lib

import { Icon } from "@iconify/react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import CheckoutHeader from "./components/checkout.header";
import CheckoutForm from "./components/checkout.form";
import PageHeader from "../../components/common/page.header";
import { useDispatch, useSelector } from "react-redux";
import Bank from "./modals/bank";
import RiderContact from "./modals/rider.contact";
import Login from "../Login";
import SetAddress from "../Store/modals/set.address";
import SaveAddress from "../Store/modals/save.address";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";
import { getOutletInfo } from "../../redux/reducer/menuReducer";
import { getCartCount, getCartInfo } from "../../redux/reducer/checkoutReducer";
import { getAddressList } from "../../redux/reducer/storeReducer";
import AddToCart from "../Menu/modals/add.to.cart";
import ReOrder from "../Menu/modals/re.order";
import ConfirmationModal from "../../components/common/confirmation.modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { openSetAddressModal } from "../../redux/reducer/modalReducer";
import { toast } from "react-toastify";

//assets

//reducers

//components

//socket
import Pusher from "pusher-js";
import { setFullPageLoading } from "../../redux/reducer/commonReducer";

export default function Checkout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();

  const { outletId, deliveryAddressId } = useSelector((state) => state.common)
  const { addressList } = useSelector((state) => state.store)
  const { outletInfo } = useSelector((state) => state.menu)
  const { bankList, cartInfo } = useSelector((state) => state.checkout)
  const { isOpenChangeCartModal, isOpenCartModal, isOpenLoginModal, isOpenBankModal, isOpenReceiptModal, isOpenRiderContactModal, isOpenReOrderModal, isOpenSetAddressModal, isOpenSaveAddressModal } = useSelector((state) => state.modal)

  const shipping = localStorage.getItem('shipping')
  const invitationCode = localStorage.getItem('invitationCode')
  const token = localStorage.getItem("token")
  const userId = localStorage.getItem("userId")
  const login = localStorage.getItem("login")

  const username = localStorage.getItem("username")
  const checkedUsername = username && username !== undefined && username.charAt(0) !== '6' ? `60${username}` : username;
  
  const openModal = searchParams.get("open")
  
  //connect pusher 
  useEffect(() => {
    if ((login && login !== undefined) && 
        (userId && userId !== undefined) && 
        (username && username !== undefined)
    ) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: `+${checkedUsername}`,
          },
        },
      });

      const channel = pusher.subscribe("presence-user" + userId);
      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel.bind("order-paid-event", (data) => {
        if (data) {
          dispatch(setFullPageLoading(false))

          if ((data.data.payment_status && data.data.payment_status !== undefined) 
            && (data.data.order_id && data.data.order_id !== undefined)) {

            if(data.data.payment_status === 'active' && data.data.order_id !== null)
              navigate(`/order?status=success&order_id=${data.data.order_id}`)
            }
        }
      });

      return () => {
        channel.unbind("order-paid-event");
        pusher.disconnect();
      };
    }
  }, [login]);

  useEffect(() => {
    if(openModal === 'set_address') {
      dispatch(getAddressList())
      dispatch(openSetAddressModal())
    }
  }, [openModal])

  useEffect(() => {
    //call cart info
    dispatch(getCartInfo({
        address_id: deliveryAddressId,
        invitation_code: invitationCode !== null ? invitationCode : '',
        delivery_method: "CAR",
    }))
    .unwrap()
    .then((res) => {
      if(res.data.carts.length > 0) {
        localStorage.setItem('cartId', res.data.carts[0].cart_id)
      }
    })
    .catch((ex) => {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            localStorage.removeItem('invitationCode')
            toast.error(
              errors[item][0], {
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              }
            );
          });
        }
      }
    })
    
    //call outlet info
    dispatch(getOutletInfo({
      id: outletId,
    }))

    //call cart counts
    dispatch(getCartCount());
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <MDBContainer className="checkout-page-container">
        <PageHeader icon="bx:food-menu" subtitle="Order Summary" >Confirm Order</PageHeader>
        <CheckoutForm outlet={outletInfo} cart={cartInfo ? cartInfo : {}} />
      </MDBContainer>
      {isOpenBankModal && <Bank banks={bankList} />}
      {isOpenRiderContactModal && <RiderContact />}
      {isOpenReOrderModal && <ReOrder />}
      {isOpenLoginModal && <Login />}
      {isOpenSetAddressModal && <SetAddress />}
      {isOpenSaveAddressModal && <SaveAddress />}
      {isOpenCartModal && <AddToCart />}
      {isOpenChangeCartModal && <ConfirmationModal />}
    </>
  );
}
