import { useState } from "react";

//lib
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//reducers
import { useDispatch } from "react-redux";
import { getReceiptInfo } from "../../../redux/reducer/orderReducer";
import { openReceiptModal } from "../../../redux/reducer/modalReducer";


export default function OrderInfo({ order }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenReceipt = (id) => {
    dispatch(openReceiptModal());
    navigate(`/order?open=receipt&id=${id}`)

    dispatch(
      getReceiptInfo({
        order_id: id,
      })
    );
  };

  let displayOrderStatus = '';
                                            
  switch (order.delivery_status) {
  case 'ASSIGNING_DRIVER':
      displayOrderStatus = 'Preparing';
      break;
  case 'ON_GOING':
      displayOrderStatus = 'On The Way';
      break;
  case 'PICKED_UP':
      displayOrderStatus = 'On The Way';
      break;
  case 'COMPLETED':
      displayOrderStatus = 'Completed';
      break;
  case 'REJECTED':
      displayOrderStatus = 'Preparing';
      break;
  case 'EXPIRED':
      displayOrderStatus = 'Preparing';
      break;
  case 'CANCELLED':
      displayOrderStatus = 'Preparing';
      break;
  case 'FAILED':
      displayOrderStatus = 'Preparing';
      break;
  default:
      displayOrderStatus = 'Preparing';
  }

  return (
    <MDBRow>
      <MDBContainer className="mt-0">
        <div className="flex mt-4 items-baseline">
          <MDBCol className="col-6 p-0">
            <label className="_label --order-label">Receipt Number</label>
          </MDBCol>
          <MDBCol
            className="col-6 text-end"
            onClick={() => handleOpenReceipt(order.order_id)}
          >
            <label className="_label --order-no pointer">
              {order.receipt_no}
            </label>
          </MDBCol>
        </div>
        <div className="flex mt-2">
          <MDBCol className="col-9 p-0">
            <div className="_label --order-label flex items-center">
              Order Time &nbsp;
              <div
                data-tooltip-id="info-tooltip" 
              >
                <Icon
                  icon="ic:outline-info"
                  color={"#fdfdff"}
                  width={16}
                  height={16}
                  className="pointer mb-1"
                />
                <div>
                  <ReactTooltip
                    id="info-tooltip" 
                    effect="solid"
                    place="right-start"
                    className="custom-tooltip relative"
                  >
                    Time when order created.
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-3 text-end">
            <label className="_label --order-data">{order.order_time}</label>
          </MDBCol>
        </div>
        <div className="flex mt-2">
          <MDBCol className="col-9 p-0">
            <label className="_label --order-label">Order Type</label>
          </MDBCol>
          <MDBCol className="col-3 text-end">
            <label className="_label --order-data">{order.method}</label>
          </MDBCol>
        </div>
        <div className="flex mt-2">
          <MDBCol className="col-4 p-0">
            <label className="_label --order-label">Served By</label>
          </MDBCol>
          <MDBCol className="col-8 text-end">
            <label className="_label --order-data">{order.merchant}</label>
          </MDBCol>
        </div>
        {order.method === 'Delivery' && order.delivery_address && <div className="flex mt-2">
          <MDBCol className="col-lg-9 col-md-7 col-4 p-0">
            <label className="_label --order-label">Deliver To</label>
          </MDBCol>
          <MDBCol className="col-lg-3 col-md-5 col-8 text-end">
            <label className="_label --order-data">{order.delivery_address.address}</label>
          </MDBCol>
        </div>}
        {order.method === 'Delivery' && <div className="flex mt-2">
          <MDBCol className="col-lg-9 col-md-7 col-4 p-0">
            <label className="_label --order-label">Deliver Status</label>
          </MDBCol>
          <MDBCol className={`col-lg-3 col-md-5 col-8 text-end ${order.share_link !== null ? 'pointer' : ''}`}
            onClick={() => {
              if(order.share_link !== null) {
                window.open(order.share_link, '_blank');
              }
            }}
          >
            <label className={`_label --order-data ${order.share_link !== null ? 'pointer' : ''}`}>
              {displayOrderStatus}
              {order.share_link !== null && order.delivery_status !== 'ASSIGNING_DRIVER' && <Icon 
                  icon="line-md:external-link" 
                  color={"#F9A01B"} 
                  width={20} 
                  height={20} 
                  className="ms-2 pointer"
              />}
            </label>
          </MDBCol>
        </div>}
        {order.remark && <div className="flex mt-2">
          <MDBCol className="col-4 p-0">
            <label className="_label --order-label">Special Remark</label>
          </MDBCol>
          <MDBCol className="col-8 text-end">
            <label className="_label --order-data">{order.remark}</label>
          </MDBCol>
        </div>}
        <div className="total-paid flex mt-3">
          <MDBCol className="col-9 p-0 mt-2">
            <label className="_label --paid-label">Total Paid</label>
          </MDBCol>
          <MDBCol className="col-3 text-end mt-2">
            <label className="_label --paid-data">
              RM {parseFloat(order.total_price).toFixed(2)}
            </label>
          </MDBCol>
        </div>
      </MDBContainer>
    </MDBRow>
  );
}
