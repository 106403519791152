//lib
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//redux
import { useSelector } from "react-redux";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const copyrightText = `Copyright © ${currentYear} Memorie Tart (1523443-V) All rights reserved.`;

  return (
    <>
      <MDBContainer className="footer-container" breakpoint="none">
        <div className="flex justify-between">
          <label className="_label --company_name">
            {copyrightText}
          </label>
          <label className="_label --company_name">
            Version {process.env.REACT_APP_VERSION}
          </label>
        </div>
      </MDBContainer>
    </>
  );
}
