import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

//redux
import { useDispatch, useSelector } from "react-redux";
import apiServices from "../../services/api.services";
import { getCartCount, getCartInfo, setLimitedError } from "../../redux/reducer/checkoutReducer";
import { toast } from "react-toastify";
import { Skeleton } from "primereact/skeleton";

export default function InputCount({
  className,
  quantity,
  setQuantity,
  handleChangeQuantity,
  index,
  food_id,
  setId,
  ticket_id,
  max,
  remark,
  balance,
  quantitySum,
  cartIndex,
  setQuantityChange,
  update_id
}) {
  const dispatch = useDispatch();

  const [number, setNumber] = useState(quantity);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { deliveryAddressId } = useSelector((state) => state.common);
  const { addressList } = useSelector((state) => state.store);
  const { updateCartLoading } = useSelector((state) => state.checkout);

  const shipping = localStorage.getItem("shipping");
  const invitationCode = localStorage.getItem("invitationCode");

  const updateCart = async ({ no, original }) => {
    setNumber(no);
    if (food_id && no !== 0) {
      try {
        setUpdateLoading(true)
        const response = await apiServices.updateCart({
          cart_item_id: food_id ? food_id : "",
          quantity: no ? no : 0,
          remark: remark ? remark : "",
        });
        if (response) {
          if (setId) {
            setId(food_id);
          }
          dispatch(getCartCount());
          dispatch(
            getCartInfo({
              address_id: deliveryAddressId,
              invitation_code: invitationCode !== null ? invitationCode : "",
              delivery_method: "CAR",
            })
          ) .then((res) => {
            setId('')
            setUpdateLoading(false)
          })
          .catch((ex) => {
            if (ex && ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  localStorage.removeItem('invitationCode')
                  toast.error(
                    errors[item][0], {
                      theme: "light",
                      hideProgressBar: true,
                      closeOnClick: true,
                      closeButton: false,
                    }
                  );
                });
              }
            }
          })
        }
      } catch (ex) {
        setId('');
        setNumber(original);
        setUpdateLoading(false);
        if (ex && Object.keys(ex).length > 0) {
          if (ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
              });
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    setNumber(quantity)
  }, [quantity]);

  return (
    <div className={`element _input-count ${className ? className : ""}`}>
      <div className="count-button">
        <MDBCol className="col-4 pointer">
          <Icon
            onClick={() => {
              if(!updateLoading) {
                if(setQuantityChange) {
                  setQuantityChange(true)
                }

                if (number !== 1 && !quantity <= 1 && max == null) {
                  // setNumber(number - 1);
                  updateCart({ no: number - 1, original: number });
                  index == null
                    ? dispatch(setQuantity(number - 1))
                    : dispatch(
                        handleChangeQuantity({ index: index, type: "decreases" })
                      );
                } else if (max && !number <= 1 && number !== 1) {
                  setNumber(number - 1);
                  dispatch(setQuantity(number - 1));
                }
              }
            }}
            icon={"ic:outline-minus"}
            color={updateLoading ? "#9091A0" : "#FDFDFF"}
          />
        </MDBCol>
        <MDBCol className="col-4 mt-1">
          {updateCartLoading && food_id === update_id ? 
            <Icon
              icon="line-md:loading-twotone-loop"
              width="50px"
              height="50px"
              color="#fdfdff"
              className="ms-3 mb-1"
            />
          :
            <label
              style={{
                color:
                  (max !== null && number > max) ||
                  (balance !== undefined && quantitySum >= balance) ||
                  (balance !== undefined && number > balance)
                    ? "#9091A0"
                    : "#fdfdff",
              }}
            >
              {number}
            </label>
          }
        </MDBCol>
        <MDBCol className="col-4 pointer">
          <Icon
            style={{
              color:
                (max !== null && number >= max) ||
                (balance !== undefined && quantitySum >= balance) ||
                (balance !== undefined && number >= balance) || 
                updateLoading
                  ? "#9091A0"
                  : "#fdfdff",
            }}
            onClick={() => {
              if(!updateLoading) {
                if(setQuantityChange) {
                  setQuantityChange(true)
                }

                if (
                  number < 99 &&
                  quantity < 99 &&
                  max == null &&
                  balance == undefined
                ) {
                  // setNumber(number + 1);
                  updateCart({ no: number + 1, original: number });
                  index == null
                    ? dispatch(setQuantity(number + 1))
                    : dispatch(
                        handleChangeQuantity({ index: index, type: "increases" })
                      );
                }
                if (
                  balance !== undefined &&
                  number < 99 &&
                  number < balance &&
                  balance > quantitySum &&
                  max == null
                ) {
                  // setNumber(number + 1);
                  updateCart({ no: number + 1, original: number });
                  if(index == null) {
                    dispatch(setQuantity(number + 1))
                  } else {
                    dispatch(
                      handleChangeQuantity({ index: index, type: "increases" })
                    );
                  }
                } else if (max && number < max && balance == undefined) {
                  setNumber(number + 1);
                  dispatch(setQuantity(number + 1));
                } else {
                  dispatch(setLimitedError(true))
                }
              }
            }}
            icon={"ic:outline-plus"}
          />
        </MDBCol>
      </div>
    </div>
  );
}
