import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";

import useWindowDimensions from "../hook/use.window.dimensions";
import { useDispatch } from "react-redux";
import { setShowModal } from "../../redux/reducer/modalReducer";

export default function ModalHeader({
  title,
  backButton,
  backButtonNoAnimation,
  shareButton,
  handleShareReceipt
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false))
    setTimeout(() => {
      backButton();
    }, 500);
  };

  return (
    <MDBContainer className="m-0 p-0">
      <div
        className="modal-header-container"
        style={{
          position: width >= 991 ? "sticky" : "fixed",
        }}
      >
        <MDBRow className="info-row">
          <MDBCol className="info-col col-2 pointer">
            {(backButton || backButtonNoAnimation) && (
              <Icon
                icon="ic:round-chevron-left"
                onClick={() => { 
                  backButtonNoAnimation ? backButtonNoAnimation() : handleCloseModalWithDelay()
                }}
                className="back-icon"
                width={28}
              />
            )}
          </MDBCol>
          <MDBCol className="info-col">
            <label style={{ fontSize: width >= 991 ? "18px" : "" }}>
              {title}
            </label>
          </MDBCol>
          <MDBCol className="info-col col-2 pointer" onClick={() => handleShareReceipt("receipt-image")}>
            {/* if unused, then can remove. */}
            {shareButton && (
              <Icon
                icon="ion:share-outline"
                width={24}
                className="mb-2"
                // color="#113B63"
              />
            )}
            {/* {infoButton && (
              <Icon
                icon="fluent:info-32-regular"
                onClick={() => infoButton()}
                className="setting-icon"
                width={24}
                color="#C5C5C5"
              />
            )} */}
          </MDBCol>
        </MDBRow>
      </div>
    </MDBContainer>
  );
}
