import { Field, useFormikContext } from "formik";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import ReactInputVerificationCode from "react-input-verification-code";

//auth

export default function CodeInput({ name, className, label, disabled }) {

const { errors, values, touched, setFieldValue } = useFormikContext();

  const [code, setCode] = useState("");

  const handleChange = (e) => {
    setCode(e);
  };

  const handleCompleted = (e) => {
    setFieldValue(name, e);
  };

  return (
    <>
      <MDBCol className={`element _code-input ${className ? className : ''}`}>
        <MDBRow>
          <label>{label}</label>
        </MDBRow>
        <MDBRow className="code-row">
          <ReactInputVerificationCode
            length={6}
            value={code}
            onChange={(e) => handleChange(e)}
            placeholder={false}
            type="password"
            onCompleted={(e) => handleCompleted(e)}
          />
        </MDBRow>
      </MDBCol>
      <MDBRow>
        {errors && touched && errors[name] && touched[name] ? (
          <div className="element _errors text-right no-padding error-message ms-3">
            {errors[name]}
          </div>
        ) : null}
      </MDBRow>
    </>
  );
}
