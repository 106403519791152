import { useEffect, useRef, useState } from "react";
//lib
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { closeWithdrawalModal, openChangeCartModal, openShareEarnModal, setAnimationModal } from "../../../redux/reducer/modalReducer";

//components
import Button from "../../../components/element/button";
import Input from "../../../components/element/input";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

//service
import apiServices from "../../../services/api.services";
import PayoutHistory from "./components/payout.history";
import { getWalletBalance, getWalletHistory } from "../../../redux/reducer/orderReducer";
import { setConfirmationItem } from "../../../redux/reducer/commonReducer";

export default function Withdrawal() {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();

    const { profileInfo, walletBalance } = useSelector((state) => state.order);
    const { isOpenWithdrawalModal } = useSelector((state) => state.modal);

    const withdrawForm = {
        amount: '0.00'
    }

    const useSchema = Yup.object({
        amount: Yup.number()
            .min(1.00, 'Minimum withdrawal: RM 1.00')
            .required('Amount is required')
    });
    
    const backButton = () => {
        dispatch(closeWithdrawalModal())
        dispatch(openShareEarnModal())
        navigate('/order?open=share_earn')
        dispatch(setAnimationModal(false))
    }

    const backToOrder = () => {
        dispatch(closeWithdrawalModal())
        navigate('/order')
    }

    const handleSubmit = async ({ values, setFieldValue }) => {
        setFieldValue('amount', '0.00')
        dispatch(setConfirmationItem({
            amount: values.amount,
            type: 'withdraw'
        }))
        dispatch(openChangeCartModal())
    }

    const handleWithdrawChange = (e, { setFieldValue }) => {
        let input = e.target.value.replace(/[^0-9]/g, '');
        input = input.replace(/^0+/, '').padStart(3, '0');
        const formattedInput = input.slice(0, -2) + '.' + input.slice(-2);
        setFieldValue('amount', formattedInput);
    }

    const handleKeyDown  = (e) => {
        if (e.key !== 'Backspace' && e.key !== 'Tab' && (e.key < '0' || e.key > '9')) {
            e.preventDefault();
        }
    };

    function formatBankAccountNumber(accountNumber) {
        if (!accountNumber) return '';
        const sanitizedNumber = accountNumber.replace(/\s/g, '');
        const groups = sanitizedNumber.match(/.{1,4}/g);
      
        if (!groups) return '';
        const maskedGroups = groups.slice(0, -1).map(group => '*'.repeat(group.length));
        const lastGroup = groups.slice(-1)[0];
      
        return [...maskedGroups, lastGroup].join(' ');
    }
      
    useEffect(() => {
        setTop(true);
    }, [isOpenWithdrawalModal]);
      
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);

    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenWithdrawalModal}
            backButton={backToOrder}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName="--white"
            content={
                <>
                    <ModalHeader title="Withdraw Profit" backButton={backButton} backButtonNoAnimation={backButton} />
                    <MDBModalBody ref={modalRef}>
                    <Formik
                        initialValues={withdrawForm}
                        validationSchema={useSchema}
                        onSubmit={(values, { setFieldError, resetForm, setFieldValue }) => {
                        handleSubmit({ values, setFieldError, setFieldValue });
                        }}
                    >
                        {({ 
                            isSubmitting, 
                            setFieldValue, 
                            values, 
                            errors,
                            isValid
                        }) => (
                        <Form>
                            <MDBContainer className="withdraw-profit-container"> 
                                <label className="_label --share-earn-desc mb-5">                                   
                                    Congratulations! You've reached your profit goals, and now it's time to enjoy the fruits of your efforts. You can easily withdraw your accumulated profits by following our straightforward withdrawal process.
                                </label>
                                <MDBRow className="big-margin">
                                    <div className="_label --withdraw-amount mt-4">
                                        Amount <br />
                                        <div className="flex items-baseline justify-center amount-grid">
                                            <div className="text-end"><span>RM</span></div>
                                            <div>
                                                <Input
                                                    name="amount" 
                                                    as="withdraw-field"
                                                    onChange={(e) => handleWithdrawChange(e, { setFieldValue })}
                                                    onKeyDown={(e) => handleKeyDown(e)}
                                                />
                                            </div>
                                        </div>
                                        {errors && errors['amount'] && (
                                            <div className="element _errors text-center no-padding error-message">
                                                {errors['amount']}
                                            </div>
                                        )}
                                    </div>
                                </MDBRow>
                                <MDBRow className="_label --available-withdraw big-margin">
                                    <div>
                                        RM {walletBalance !== null ? parseFloat(walletBalance).toFixed(2) : "0.00"} <br />
                                        <span>Available Balance</span>
                                    </div>
                                </MDBRow>
                                <MDBRow className="big-margin --withdraw text-start">
                                    <div className={`${width > 550 ? 'flex' : ''} justify-between items-center withdraw-to mt-5`}> 
                                        <div>
                                            Withdraw Profit To
                                        </div>
                                        <div className={`flex items-center ${width > 550 ? '' : 'mt-4'}`}>
                                            <div>
                                                <Icon
                                                    icon="bxs:bank"
                                                    width={26} 
                                                    height={26}
                                                    color="#f9a01b"
                                                />
                                            </div>
                                            <div className="ps-5">
                                                <div className="_label --bank-name">{profileInfo.bank}</div>
                                                <div className="_label --bank-no">{formatBankAccountNumber(profileInfo.bank_account_no)}</div>
                                            </div>
                                            <div />
                                        </div>
                                    </div>
                                </MDBRow>
                                <MDBRow className="history-line flex justify-center">
                                    <PayoutHistory />
                                </MDBRow>
                            </MDBContainer>
                            <MDBRow className="button-container m-0  --white">
                                <Button 
                                    // disabled={!isValid}
                                    type="submit"
                                >
                                    Confirm Withdraw
                                </Button>
                            </MDBRow>
                        </Form>
                        )}
                    </Formik>
                    </MDBModalBody>
                </>
            }
        />
    );
}